import React from "react";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
const CustomSwitch = withStyles({
    switchBase: {
        color: 'grey',
        '&$checked': {
            color: '#0E7D7D',
        },
        '&$checked + $track': {
            backgroundColor: '#0E7D7D',
        },
    },
    checked: {},
    track: {},
})(Switch);
export default CustomSwitch;
