
export const setFilesToUploader=async(link,setFileStacks=false,fileTreeKey='fileTree')=>{
    if(link && link[fileTreeKey]){
        const result =  await Promise.all(Object.keys(link[fileTreeKey]).map(async (fileTreeName) => {

            const files =  await Promise.all( link[fileTreeKey][fileTreeName].map(async (fileName)=>{
                // let fileUrl = `${process.env.REACT_APP_DOWNLOAD_URL}/${link.link}/${fileTreeName}/${fileName}`;
                // let response = await fetch(fileUrl);
                // let data = await response.blob();
                // let file = new File([data], fileName, );
                return {
                    name :fileName,
                    data: false
                }
            }));
            return {
                packageName: fileTreeName,
                files:[...files],
                type:fileTreeKey==='fileTree'? 'admin' : 'client',
                id: Math.random().toString(36).substr(2, 9)
            }
        }));
        if(!setFileStacks){
            return result;
        }
        setFileStacks(result);
    }
}
