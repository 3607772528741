import {
    isMobile
} from "react-device-detect";

export const downloadAction = (downloadUrl, fileName) => {
  const ext = fileName.split('.').pop();
  const url = `${process.env.REACT_APP_DOWNLOAD_URL}${downloadUrl}`;
  //   console.log(url);
  // if(isMobile){
  //     const link = document.createElement('a');
  //     link.href = url;
  //      link.setAttribute('target', '_blank');
  //     link.setAttribute('download', fileName);
  //     document.body.appendChild(link);
  //     link.click();
  // }else{

    let t;
    if(ext.toLowerCase() === 'pdf') {
         t =  window.open(url, '_blank');
        if(t==null){
            document.location.assign(url)
        }
    } else {
         t = window.open(url);
        if(t==null){
            document.location.assign(url)
        }

    }

    // }






};
