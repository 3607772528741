import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React, {useEffect, useRef, useState} from "react";
import {history} from "../utils/history";
import {copyToclipBoard} from "../utils/copyToClipboard";
import {loc} from "../localization";
import {makeStyles} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Grid from '@material-ui/core/Grid';
import AntSwitch from "../widgets/CustomSwitch";
import FileStacks from "./FileStacks";
import {setFilesToUploader} from "../utils/setFilesToUploader";

const options = Object.keys(loc);

const useStyles = makeStyles({
    root: {
        background: "#F7F7F7",
        borderRadius: 10,
        display: "flex",
        justifyContent: "space-between",
        padding: "7px 0px 7px 14px",
        width: "100%",
        marginBottom: 10,
        color: "#979797",
    },
    button: {
        maxWidth: "186px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: 3,
        textTransform: "none",
        padding: "14px 24px",
        color: '#444444',
        marginBottom: 40,
        fontWeight: 600,
        fontSize: 12
    },
    buttonClose: {
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: 3,
        padding: "8",
    },
    linkButton: {
        flexGrow: 1,
        background: "#0E7D7D",
        borderRadius: 3,
        textTransform: "none",
        padding: "13px",
        boxSizing: "border-box",
        color: "#fff",
        fontWeight: 600,
        transition: "all .2s ease-in",
        "&:hover": {
            background: "#0E7D7D",
            opacity: 0.7,
        },
    },
    downloadLink: {
        color: "#0E7D7D",
        textTransform: "none",
    },
    select: {
        marginRight: 25,
        color: '#767676',
        fontWeight: 600,
    },
    input: {
        padding: "5px 0 5px 30px",
        width: "100%",
        background: "#F7F7F7",
        borderRadius: 3,
        fontSize: 14,
    },
    nameInputContainer: {
        marginBottom:"2%",
        width:"60%",
        padding:"5px 20px 5px 10px",
        display:"flex",
        "&>img":{
            position:"relative",
            right: "5%",
            top: "25%",
            cursor:"pointer"
        }
    },
    nameInput: {
        width: "100%",
        fontSize: 14,
        borderBottom:"1px solid rgb(14 125 125 / 30%)",
        marginBottom:"2%",
    },

});
const defaultFileStack = { packageName: "", files: [], id: Date.now() };
const UploadFiles=({
 getLinks,
 uploadFiles,
 isLoading,
 resetLastCreateLink,
 lastCreated,
 lastUpdated,
 isAuth,
 adminEdit
})=>{
    const [allName, setAllName] = useState((adminEdit && adminEdit.allName) ? adminEdit.allName :'');
    const [fileStacks, setFileStacks] = useState([defaultFileStack]);
    const [language, setLanguage] = useState(options[0]);
    const classes = useStyles();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isCanceled, setIsCanceled] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);

    useEffect(() => {
        if(!isAuth) {
            history.push('/login')
        }
    },[isAuth]);


    /**
     * Editing
     */
    useEffect(() => {


        if(!adminEdit){
            setFileStacks([defaultFileStack]);
            setLanguage(options[0]);
            setAllName('');
            setAllowEdit(false);
        }else{
            setAllName((adminEdit && adminEdit.allName) ? adminEdit.allName :'');
            setLanguage(adminEdit.language || options[0] );
            setAllowEdit(adminEdit.allowEdit || false );
            setAllEditFiles();
        }
    },[adminEdit]);

    const setAllEditFiles =async()=>{
        if(adminEdit){
            let files = await setFilesToUploader(adminEdit,false,'fileTree') || [];
            let clientFiles = await setFilesToUploader(adminEdit,false,'clientFileTree') || [];
            setFileStacks(files.concat(clientFiles))
        }
    };

    const cancelFileUpload = useRef(null);

    // TODO get rid of all this local bools, use pattern: request, success, failure
    // saga routines or something like this
    const upload = () => {
        setIsCanceled(false);
        let params = { allName, allowEdit,fileStacks, language, id: Date.now(), setUploadProgress: setUploadProgress, cancelFileUpload: cancelFileUpload };

        if(adminEdit && adminEdit._id){
            params.link_id = adminEdit._id;
        }

        uploadFiles(params);
    };

    const onCancelUpload = () => {
        if(cancelFileUpload.current) {
            cancelFileUpload.current("User has canceled the file upload.");
            setUploadProgress(0);
            setIsCanceled(true);
        }
    };

    const copyClipBoard = async (e) => {
        if (window.navigator?.clipboard)
            await window.navigator.clipboard.writeText(lastCreated.address);
        else copyToclipBoard(lastCreated.address);
        e.target.innerText = "Copied";
    };

    const changeLanguage = (lang) => {
        setLanguage(lang);
    };

    const changePackageName = (id, name) => {
        const result = fileStacks.map((fileStack) => {
            if (fileStack.id === id) {


                let newName = name.toString().includes('.') ? fileStack.packageName : name

                return {
                    ...fileStack,
                    packageName: newName,
                };
            } else return fileStack;
        });
        setFileStacks(result);
    };

    const setPackageFiles = (id, files) => {
        const result = fileStacks.map((fileStack) => {
            if (fileStack.id === id) {
                return {
                    ...fileStack,
                    files,
                };
            } else return fileStack;
        });
        setFileStacks(result);
        setIsCanceled(false);
    };

    const deleteFileStack = (id) => {
        if (fileStacks.length <= 1) return;
        setFileStacks(fileStacks.filter((fileStack) => fileStack.id !== id));
    };

    useEffect(() => {
        getLinks();
        return () => resetLastCreateLink();
    }, [lastUpdated]);

    return <div className="admin-upload-files-container">
        <div className="admin-upload-files-container-wrapper">
            <h1>{adminEdit ? 'Editing' : 'Upload files'}</h1>
            <div className={classes.nameInputContainer}>
                <Input
                    className={classes.nameInput}
                    disableUnderline
                    placeholder="Title"
                    value={allName}
                    onChange={(e) => setAllName(e.target.value)}
                />
            </div>
            <FileStacks
                fileStacks={fileStacks}
                changePackageName={changePackageName}
                setPackageFiles={setPackageFiles}
                deleteFileStack={deleteFileStack}
                setFileStacks={setFileStacks}
                onCancelUpload={onCancelUpload}
                setUploadProgress={setUploadProgress}
                uploadProgress={uploadProgress}
                isLoading={isLoading}
                isCanceled={isCanceled}
            />

            <div>
                {!isLoading && (
                        !lastCreated ? (
                            <React.Fragment>
                                <div className="d-flex p-0">
                                    <Grid component="label" container alignItems="center" spacing={3} >
                                        <Grid item className="m" >Back upload: </Grid>
                                        <Grid item>OFF</Grid>
                                        <Grid item>
                                            <AntSwitch
                                                name="allow_editing"
                                                value={allowEdit}
                                                checked={allowEdit}
                                                onChange={(e,value)=>{setAllowEdit(value);}}
                                            />
                                        </Grid>
                                        <Grid item>ON</Grid>
                                    </Grid>
                                </div>
                            <div className="d-flex p-0 m-1">
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    className={classes.select}
                                    variant="outlined"
                                    value={language}
                                    onChange={(e) => changeLanguage(e.target.value)}
                                    autoWidth
                                >
                                    {options.map((option) => (
                                        <MenuItem value={option} key={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button onClick={upload} className={classes.linkButton}>
                                    {adminEdit ? 'Save changes' : 'Get a link'}
                                </Button>
                            </div>
                            </React.Fragment>
                        ) : (
                            <>
                                <p className="download-link-description">
                                    Your download link
                                </p>
                                <div className="download-link-text">
                                    <p>{lastCreated.address}</p>
                                    <Button
                                        className={classes.downloadLink}
                                        onClick={(e) => copyClipBoard(e)}
                                    >
                                        Copy
                                    </Button>
                                </div>
                            </>
                        )
                    )
                }
                {isLoading && isCanceled && <span className={classes.cancelText}>User has canceled the file upload.</span>}
            </div>
        </div>
    </div>
};
export default UploadFiles;
