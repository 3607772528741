import axios, {CancelToken} from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (res) => res.data,
  async (e) => {
    await Promise.reject(e.response.data);
  }
);

export const downloadFile = async ({ linkId, parent, fileName, download }) => {

  console.log('fileName',fileName);
  console.log(linkId, parent, fileName, download );
  console.log(`/download/${linkId}/${parent}/${fileName}`);
  return await api.get(`/download/${linkId}/${parent}/${encodeURIComponent(fileName)}`, {
    headers: { download },
  });
};

export const downloadFolder = async ({ linkId, parent }) => {
  return await api.get(`/download/${linkId}/${parent}`);
};

export const getFileTree = async (id) => {
  return await api.get(`/files/${id}`);
};

export const getLinks = async (payload) => {

  let limit = process.env.REACT_APP_PAGINATION_LIMIT || 10;
  let page = payload.page || 1;

  let url  = `/links?limit=${limit}&page=${page}`;

  if(payload.search){
    url+=`&search=${payload.search}`
  }



  return await api.get(url);
};

export const deleteLink = async (id) => {
  return await api.delete(`/link/${id}`);
};

export const uploadFiles = async (data) => {

  const url = data.link_id ? `/update/${data.link_id}` : `/upload/${data.id}`;

  return await api.post(url, data.files, {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      data.setUploadProgress(percentCompleted);
    },
    cancelToken: new CancelToken(cancel => data.cancelFileUpload.current = cancel),
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
      lang: data.lang,
    },
  })
};
