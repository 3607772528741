import LocalizedStrings from 'react-localization';

export const loc = {
  EN: {
    clientFiles: "We've prepared your files...",
    contactUs: "Contact us on ",
    phone: "+44 7588 074088",
    or: "or",
    email: "info@kingsoftranslation.co.uk",
    questions: "if you have any questions.",
    noClientFiles: "Hold on. There are no files on the server…",
    downloadAll: "Download all",
    prepToDownload: "Getting ready to download…",
    uploadBack: "Upload files:",
    saveChanges: "Save changes",
    edit: "Edit",
    addMore: "Add one more",
    uploadFiles: "Upload files",
    fileNames: "Name",
    dragAndDropFiles: "Drag & drop files",

  },
  LT: {
    clientFiles: "Atsisiųskite Jums paruoštus failus:",
    contactUs: "Skambinkite ",
    phone: "+370 630 09 360",
    or: "arba rašykite",
    email: "info@vertimukaraliai.lt",
    questions: "ir atsakysime į Jūsų klausimus!",
    noClientFiles: "Serveryje įkeltų failų neradome. Bandykite vėliau.",
    downloadAll: "Parsisiųsti viską",
    prepToDownload: "Ruošiamasi atsisiuntimui...",
    uploadBack: "Įkelkite failus:",
    saveChanges: "Išsaugoti pakeitimus",
    edit: "Redaguoti",
    addMore: "Pridėti archyvą",
    uploadFiles: "Įkelti",
    fileNames: "Pavadinimas (neprivaloma)",
    dragAndDropFiles: "Įkelkite arba nutempkite failus čia",



  },
  ES: {
    clientFiles: "¡Hemos preparado los archivos para ti!",
    contactUs: "Contacta con nosotros en ",
    phone: "+44 020 8144 8051",
    or: "o",
    email: "info@kingsoftranslation.co.uk",
    questions: "si tienes alguna pregunta.",
    noClientFiles: "Aún no hay archivos en el servidor ...",
    downloadAll: "Descargar todo",
    prepToDownload: "Preparando para bajar...",
    uploadBack: "Upload back files",
    saveChanges: "Save Changes",
    edit: "Edit",
    addMore: "Add one more",
    uploadFiles: "Upload files",
    fileNames: "File names",
    dragAndDropFiles: "Drag & drop files",


  },
  RU: {
    clientFiles: "У нас тут файлы для вас",
    contactUs: "Свяжитесь с нами ",
    phone: "+370 630 09 360",
    or: "или",
    email: "info@vertimukaraliai.lt",
    questions: "если у вас есть вопросы",
    noClientFiles: "На сервере нет файлов",
    downloadAll: "Скачать папку",
    prepToDownload: "Подготовка файлов...",
    uploadBack: "Закачать файлы",
    saveChanges: "Сохранить",
    edit: "Изменить",
    addMore: "Добавить еще",
    uploadFiles: "Закачать файлы",
    fileNames: "Названия файлов",
    dragAndDropFiles: "Перетянуть файлы",

  },
};

const T = new LocalizedStrings(loc);

export default T
