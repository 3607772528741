import React from "react";
import DownloadItem from "./DownloadItem";
import DownloadHeader from "./DownloadHeader";


const DownloadContainer = ({
  parent,
  files,
  id,
  downloadFile,
  downloadFolder,
  T,
}) => {

  const downloadItem = (fileName, download) => {
    downloadFile({ linkId: id, parent, fileName, download });
  };

  return (
    <div className="download-container">
      <DownloadHeader
          parent={parent}
          files={files}
          T={T}
          downloadFolder={downloadFolder}
          id={id}
    />
      <div className="pb-12">
        <div className="download-container-items">
          {files.length &&
            files.map((file, index) => (
              <DownloadItem
                download={downloadItem}
                fileName={file}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default DownloadContainer;
