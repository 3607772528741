import { connect } from "react-redux";
import AdminPage from "../pages/AdminPage";
import {
  getLinks,
  uploadFiles,
  resetLastCreateLink,
  deleteLink,
  setIsAuth,
  setAdminEdit,
  setPage, setSearch
} from "../redux-store/actions";

const mapStateToProps = ({ links: { links, lastCreated,lastUpdated,adminEdit ,limit,page,search} , errorLoading: { isLoading, isAuth }}) => ({
  links, isLoading, lastCreated, lastUpdated, isAuth,adminEdit,limit,page,search
});

const mapDispatchToProps = (dispatch) => ({
  getLinks: (query) => dispatch(getLinks(query)),
  uploadFiles: (data) => dispatch(uploadFiles(data)),
  resetLastCreateLink: () => dispatch(resetLastCreateLink()),
  deleteLink: (id) => dispatch(deleteLink(id)),
  setAdminEdit: (id) => dispatch(setAdminEdit(id)),
  setIsAuth: (isAuth) => dispatch(setIsAuth(isAuth)),
  setPage: (page) => dispatch(setPage(page)),
  setSearch: (query) => dispatch(setSearch(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
