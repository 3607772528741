import { takeLatest, put, select } from "redux-saga/effects";
import { safe } from "./errorHandler";
import { getLinks, deleteLink } from "../../services/api";
import {GET_LINKS, SET_LINKS, DELETE_LINK, SET_SEARCH} from "../actions/types";

const getAllLinks = function* ({payload}) {
  const links = yield getLinks(payload);

  yield put({type: SET_LINKS, payload: links});
};

const deleteFilesLink = function* ({ payload }) {
  yield deleteLink(payload);
  const  limit = yield select((state) => state.links.limit);
  const  page = yield select((state) => state.links.page);
  const  search = yield select((state) => state.links.search);
  yield put({type: GET_LINKS, payload: {limit:limit,page:page,search:search}});
};

const linksSagas = [
  takeLatest(GET_LINKS, safe(getAllLinks)),
  takeLatest(DELETE_LINK, safe(deleteFilesLink)),
];

export default linksSagas
