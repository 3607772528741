import React from "react";
import FileStack from "./FileStack";
import addIcon from "../assets/images/plus_no_bg.svg";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import styled from "styled-components";
import T from "../localization";
import DownloadContainer from "./DownloadContainer";
const StyledProgressBar = styled.div`
  width: ${({ progress }) => progress + "%"};
`;
const useStyles = makeStyles({
    button: {
        maxWidth: "186px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: 3,
        textTransform: "none",
        padding: "14px 24px",
        color: '#444444',
        marginBottom: 40,
        fontWeight: 600,
        fontSize: 12
    },
    progressBarContainer:{
        width: "100%",
        display: "flex",
        height: "35px",
        marginBottom: "15px"
    },
    progressBar: {
        width: "90%",
        height: "35px",
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: "2px",
        marginBottom: "15px",
        backgroundColor: "#0e7d7d47",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontWeight: "bold",
        fontSize: "14px"

    },
    progressBarInner: {
        transitionDuration: ".3s",
        background: "#0E7D7D",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        marginRight: "auto",
        borderRadius: "3px"
    },
    progress: {
        position: "absolute",
    },
    cancelBut: {
        height: "100%",
        color: "#fe5b5b",
        fontWeight: "bold",
        fontSize: "12px",
        marginLeft: "8px"
    },
    cancelText: {
        fontWeight: "bold",
        fontSize: "14px",
        marginBottom: "15px"
    }
});

const FileStacks=
    ({
         fileStacks,
         changePackageName,
         setPackageFiles,
         onCancelUpload,
         deleteFileStack,
         setFileStacks,
         isLoading,
         isCanceled,
         uploadProgress,
         client,
         downloadFolder,
         downloadFile,
         id,
         showEdit,
         fileTree,
     })=>{
        const classes = useStyles();
    return <>
            {fileStacks.map((fileStack,index) => (
                <React.Fragment   key={index}>

                    {!showEdit && downloadFile ?
                        <DownloadContainer
                            T={T}
                            parent={fileStack.packageName}
                            files={(fileTree && fileTree[fileStack.packageName]) || []}
                            id={id}
                            downloadFile={downloadFile}
                            downloadFolder={downloadFolder}
                        />
                        :
                        <FileStack
                            T={T}
                            id={fileStack.id}
                            packageName={fileStack.packageName}
                            changeName={changePackageName}
                            files={fileStack.files}
                            setFiles={setPackageFiles}
                            deleteFileStack={deleteFileStack}
                            type={fileStack.type}
                            downloadFile={downloadFile}

                        />

                    }


                </React.Fragment>
            ))}
        {(showEdit) &&
            <Button
                    className={classes.button}
                    endIcon={<img src={addIcon} alt="add" className="add-icon-margin" />}
                    onClick={(e) =>
                        setFileStacks([
                            ...fileStacks,
                            { packageName: "", files: [], id: Date.now() },
                        ])
                    }
                >
                    {T.addMore}
                </Button>
        }
        {!!isLoading&&
        <React.Fragment>
            {(isLoading && fileStacks[0] &&  fileStacks[0].files.length > 0 && (uploadProgress < 100 || (client &&uploadProgress <=100))) &&
            <div className={classes.progressBarContainer}>
                <div className={classes.progressBar}>
                    <span className={classes.progress}>{uploadProgress}%</span>
                    <StyledProgressBar className={classes.progressBarInner} progress={uploadProgress} />
                </div>
                <Button className={classes.cancelBut} onClick={onCancelUpload}>Cancel</Button>
            </div>}
            {(!client && isLoading && (!fileStacks || !fileStacks[0] || !fileStacks[0].files  || !fileStacks[0].files.length< 1) && uploadProgress === 100) &&
            <p>Generating the link...</p>}
        </React.Fragment>
        }
        { isLoading && isCanceled && <span className={classes.cancelText}>User has canceled the file upload.</span>}
        </>
};
FileStacks.defaultProps={
    showEdit:true
}
export default FileStacks
