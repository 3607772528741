import React from "react";
import "../assets/styles/components/visited-item.scss";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";
import closeIcon from "../assets/images/icon_close.svg";
import editIcon from "../assets/images/icon_edit.svg";
import { copyToclipBoard } from "../utils/copyToClipboard";

const useStyles = makeStyles({
  paper: {
    marginRight: 5,
    marginBottom: 5,
    boxSizing: "border-box",
  },
  paperLink: {
    // transition: "all .4s ease-in",
    position: "relative",
    overflowX: "hidden",


    "&::before": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      visibility: "visible",
      content: "''",
      right: "-70%",
      background: "linear-gradient(" +
          "to right,  " +
          "rgba(0,0,0,0)," +
          "rgba(255,255,255,0.4) 3%," +
          "rgba(255,255,255,0.5) 10%," +
          "rgba(255,255,255,0.7) 20%," +
          "rgba(255,255,255,0.8) 30%," +
          "rgba(255,255,255,0.9) 40%," +
          " #fff 100%)",
    },
    "&:hover": {
      backgroundColor: "#ccc",
      cursor: "pointer",
      zIndex:2
    },
    "&:hover:before":{
      content: "none",
      backgroundColor: "#ccc",
    }

  },
});

const Visited = ({ link, ips, removeLink,editLink, id ,linkObject}) => {
  const classes = useStyles();

  return (
    <div className="main-container">
      {linkObject.allName&&
        <div className="all-name">{linkObject.allName}</div>
      }
      <div className="visited-container">
        <div className="row-container">
          <div className="visited-container-row">
            <h3>Link:</h3>
            <div className="paper-container main-link">
              <Paper
                  elevation={0}
                  className={classes.paperLink}
                  onClick={(e) => copyToclipBoard(link)}
              >
                <h4>{link}</h4>
              </Paper>
            </div>
          </div>
          <div className="visited-container-row align-items-none">
            <h3 className="grey second-row-m">IP's visited:</h3>
            <div className="paper-container">
              {(ips && ips.length)
                  ? ips.map((ip, index) => (
                      <Paper
                          elevation={0}
                          key={index}
                          className={`${classes.paperLink} ${classes.paper}`}
                          onClick={(e) => copyToclipBoard(ip)}
                      >
                        <p>{ip}</p>
                      </Paper>
                  ))
                  : null}
            </div>
          </div>
        </div>
        <div className="icon-container">
          <img
              src={editIcon}
              alt="edit"
              className="icon-edit"
              width={16}
              height={15}
              onClick={(e) => editLink(linkObject)}
          />
          <img
              src={closeIcon}
              alt="close"
              width={16}
              height={15}
              className="icon-close"
              onClick={(e) => removeLink(id)}
          />
        </div>
      </div>
    </div>

  );
};

export default Visited;
