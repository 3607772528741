import FileStacks from "./FileStacks";
import React, {useEffect, useRef, useState} from "react";
import T from "../localization";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {setFilesToUploader} from "../utils/setFilesToUploader";
import editIcon from "../assets/images/icon_edit.svg";

const defaultFileStack = { packageName: "", files: [], id: Date.now() };


const useStyles = makeStyles({
    linkButton: {
        flexGrow: 1,
        background: "#0E7D7D",
        borderRadius: 3,
        textTransform: "none",
        padding: "13px",
        boxSizing: "border-box",
        color: "#fff",
        fontWeight: 600,
        transition: "all .2s ease-in",
        "&:hover": {
            background: "#0E7D7D",
            opacity: 0.7,
        },
    },
    button: {
        display: "flex",
        padding: "0 16px",
        justifyContent: "center",
        alignItems: "center",
        color: "#0e7d7d",
        fontWeight: "600",
        fontSize: 12,
        borderRadius: 3,
        textTransform: "none",
        background: "#F7F7F7",
    },
});


const ClientUploadFiles=({isLoading,uploadClientFiles,downloadLink,setUploadAction,downloadFile,downloadFolder})=>{
    const [fileStacks, setFileStacks] = useState([defaultFileStack]);
    const [isCanceled, setIsCanceled] = useState(false);
    const [edit, setEdit] = useState((!downloadLink.clientFileTree ||  !downloadLink.clientFileTree.length) || false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const cancelFileUpload = useRef(null);
    const classes = useStyles();

    const onCancelUpload = () => {
        if(cancelFileUpload.current) {
            cancelFileUpload.current("User has canceled the file upload.");
            setUploadProgress(0);
            setIsCanceled(true);
        }
    };
    const changePackageName = (id, name) => {
        const result = fileStacks.map((fileStack) => {
            if (fileStack.id === id) {
                return {
                    ...fileStack,
                    packageName: name,
                };
            } else return fileStack;
        });
        setFileStacks(result);
    };

    const setPackageFiles = (id, files) => {
        const result = fileStacks.map((fileStack) => {
            if (fileStack.id === id) {
                return {
                    ...fileStack,
                    files,
                };
            } else return fileStack;
        });
        setFileStacks(result);
        setIsCanceled(false);
    };

    const deleteFileStack = (id) => {
        if (fileStacks.length <1) return;
        setFileStacks(fileStacks.filter((fileStack) => fileStack.id !== id));
    };



    const upload = () => {
        setUploadAction(true);
        setIsCanceled(false);
        let params = {link_id:downloadLink.link,_id:downloadLink._id,fileStacks,setUploadProgress: setUploadProgress, cancelFileUpload: cancelFileUpload };
        uploadClientFiles(params);
    };

    useEffect(()=>{
        setFilesToUploader(downloadLink,setFileStacks,'clientFileTree');
        if(downloadLink.clientFileTree){
            setEdit(false);
        }
    },[downloadLink]);

    useEffect(()=>{
        if(!isLoading && uploadProgress>99){
            setUploadAction(false);
            setEdit(false);
        }
    },[isLoading,uploadProgress]);
    return <>
        <h1 className={'client-upload-title'}>{T.uploadBack}</h1>
        <div className="client-upload-files">
            <div className="client-upload-files-container">
                {!edit &&

                <div className="download-container-header">
                    <Button
                        className={classes.button}
                        onClick={(e) => setEdit(!edit)}
                    >
                        <p>{T.edit}</p>
                        <img
                            src={editIcon}
                            alt="download"
                            className="icon download-icon"
                        />
                    </Button>

                </div>
                }

                <div className="client-upload-files-container-wrapper">
                    <FileStacks
                        fileStacks={fileStacks}
                        changePackageName={changePackageName}
                        setPackageFiles={setPackageFiles}
                        deleteFileStack={deleteFileStack}
                        setFileStacks={setFileStacks}
                        fileTree={downloadLink.clientFileTree}
                        setUploadProgress={setUploadProgress}
                        isCanceled={isCanceled}
                        isLoading={isLoading}
                        uploadProgress={uploadProgress}
                        onCancelUpload={onCancelUpload}
                        client={true}
                        id={downloadLink.link}
                        showEdit={edit}
                        downloadFile={downloadFile}
                        downloadFolder={downloadFolder}
                    />
                    {!!edit &&
                        <Button onClick={upload} className={classes.linkButton}>
                            {downloadLink.clientFileTree ? T.saveChanges: T.uploadFiles }
                        </Button>
                    }
                </div>
            </div>
        </div>
    </>
};
export default ClientUploadFiles;
