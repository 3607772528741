import React, {useEffect, useState} from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import DownloadContainer from "../components/DownloadContainer";
import T from "../localization";
import { LoopCircleLoading } from "react-loadingg";
import ClientUploadFiles from "../components/ClientUploadFiles";

const ClientPage = ({
  downloadLink,
  isLoading,
  getDownloadLink,
  match,
  downloadFile,
  downloadFolder,
  uploadClientFiles,
  clientEdit
}) => {

  useEffect(() => {
    if (!downloadLink) getDownloadLink(match.params.id);
    else {
      T.setLanguage(downloadLink.language);
    }
  }, [downloadLink]);

  const [uploadAction, setUploadAction] = useState(false);


  return (
    <>
      <Header />
      <div className="client-page">
        <h1>{T.clientFiles}</h1>
        {(isLoading&&!uploadAction) ? (
          <>
            <div className="progress-page">
              <LoopCircleLoading color="#0E7D7D" size="large" />
            </div>
            <h3>{T.prepToDownload}</h3>
          </>
        ) : downloadLink &&
          <>
          {Object.entries(downloadLink.fileTree).map((path, index) => (
            <DownloadContainer
              T={T}
              parent={path[0]}
              files={path[1]}
              key={index}
              id={downloadLink.link}
              downloadFile={downloadFile}
              downloadFolder={downloadFolder}
            />
          ))}
            {downloadLink.allowEdit &&
            <ClientUploadFiles
                T={T}
                isLoading={isLoading}
                uploadClientFiles={uploadClientFiles}
                clientEdit={clientEdit}
                downloadLink={downloadLink}
                downloadFile={downloadFile}
                downloadFolder={downloadFolder}
                setUploadAction={setUploadAction}
                getDownloadLink={getDownloadLink}
            />
            }

          </>

        }
      </div>
      <Footer T={T} />
    </>
  );
};

export default ClientPage;
