import { SET_LINKS, SET_LAST_CREATED_LINK, SET_DOWNLOAD_LINK,SET_ADMIN_EDIT,SET_LAST_UPDATED_LINK,SET_LIMIT,SET_PAGE,SET_SEARCH } from "../actions/types";

const initialState = {
  lastCreated: null,
  lastUpdated: null,
  links: [],
  downloadLink: null,
  adminEdit:false,
  limit: process.env.REACT_APP_PAGINATION_LIMIT || 10,
  page:1,
  search:'',
};

const links =  (state = initialState, action) => {
  switch (action.type) {
    case SET_LINKS:
      return {
        ...state,
        links: action.payload
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload
      };
    case SET_LIMIT:
      return {
        ...state,
        limit: action.payload
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case SET_LAST_CREATED_LINK:
      return {
        ...state,
        lastCreated: action.payload
      };
    case SET_LAST_UPDATED_LINK:
      return {
        ...state,
        lastUpdated: action.payload
      };
    case SET_DOWNLOAD_LINK:
      return {
        ...state,
        downloadLink: action.payload
      };
    case SET_ADMIN_EDIT:
      return {
        ...state,
        adminEdit: (!state.adminEdit || !state.adminEdit._id ||  state.adminEdit._id!==action.payload._id )? action.payload : false
      };
    default:
      return state;
  }
};

export default links
