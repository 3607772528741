export const SET_LINKS = "SET_LINKS"
export const GET_LINKS = "GET_LINKS"
export const DELETE_LINK = "DELETE_LINK"
export const SET_LIMIT = "SET_LIMIT"
export const SET_PAGE = "SET_PAGE"
export const SET_SEARCH = "SET_SEARCH"

export const SET_LAST_CREATED_LINK = "SET_LAST_CREATED_LINK"
export const SET_LAST_UPDATED_LINK = "SET_LAST_UPDATED_LINK"

export const SET_IS_AUTH = "SET_IS_AUTH"

export const GET_DOWNLOAD_LINK = "GET_DOWNLOAD_LINK"
export const SET_DOWNLOAD_LINK = "SET_DOWNLOAD_LINK"

export const SET_ADMIN_EDIT = "SET_ADMIN_EDIT"

export const DOWNLOAD_FILE = "DOWNLOAD_FILE"
export const DOWNLOAD_FOLDER = "DOWNLOAD_FOLDER"

export const UPLOAD_FILES = "UPLOAD_FILES"
export const UPLOAD_CLIENT_FILES = "UPLOAD_CLIENT_FILES"

export const SET_ERROR = "SET_ERROR"
export const SET_LOADING = "SET_LOADING"
