import { takeLatest, put, select } from "redux-saga/effects";
import { safe } from "./errorHandler";
import { uploadFiles } from "../../services/api";
import { checkFolderNames } from "../../utils/helpers";
import {
    UPLOAD_FILES,
    UPLOAD_CLIENT_FILES,
    SET_LINKS,
    SET_LAST_CREATED_LINK,
    SET_LAST_UPDATED_LINK,
    SET_ADMIN_EDIT,
    GET_DOWNLOAD_LINK, GET_LINKS
} from "../actions/types";

const uploadAllFiles = function* ({ payload }) {
  const data = new FormData();
  const checkedArray = checkFolderNames('packageName' ,payload.fileStacks)


  for(let fileStack of checkedArray) {
    if(!fileStack.files.length) return;
    for(let file of fileStack.files) {
      yield data.append(fileStack.packageName, file.data)
    }
  }
  data.append('allName', payload.allName);
  data.append('allowEdit', payload.allowEdit);
    data.append('filePackageNames', JSON.stringify(checkedArray));

  const link = yield uploadFiles({link_id :payload.link_id||false, files: data, id: payload.id, lang: payload.language, setUploadProgress: payload.setUploadProgress, cancelFileUpload: payload.cancelFileUpload});
    if(!payload.link_id){
        const  limit = yield select((state) => state.links.limit);
        const  page = yield select((state) => state.links.page);
        const  search = yield select((state) => state.links.search);

        yield put({type: GET_LINKS, payload: {limit:limit,page:page,search:search}});
      yield put({type: SET_LAST_CREATED_LINK, payload: link});
  }else{
      yield put({type: SET_LAST_UPDATED_LINK, payload: link});
      yield put({type: SET_ADMIN_EDIT, payload: false});
  }
};
const uploadClientFiles = function* ({ payload }) {
  const data = new FormData();
  const checkedArray = checkFolderNames('packageName' ,payload.fileStacks);

  for(let fileStack of checkedArray) {
    if(!fileStack.files.length) return;
    for(let file of fileStack.files) {
      yield data.append(fileStack.packageName, file.data);
    }
  }
    data.append('client', 'true');
    data.append('filePackageNames', JSON.stringify(checkedArray));

    yield uploadFiles({link_id :payload._id||false, files: data, id: payload.id, lang: payload.language, setUploadProgress: payload.setUploadProgress, cancelFileUpload: payload.cancelFileUpload});


    yield put({type: GET_DOWNLOAD_LINK, payload: payload.link_id});

};


const uploadSagas = [
    takeLatest(UPLOAD_FILES, safe(uploadAllFiles)),
    takeLatest(UPLOAD_CLIENT_FILES, safe(uploadClientFiles)),
];

export default uploadSagas
