import Button from "@material-ui/core/Button";
import downloadIcon from "../assets/images/download.svg";
import React from "react";
import {makeStyles} from "@material-ui/core";



const useStyles = makeStyles({
    button: {
        display: "flex",
        padding: "0 16px",
        justifyContent: "center",
        alignItems: "center",
        color: "#0e7d7d",
        fontWeight: "600",
        fontSize: 12,
        borderRadius: 3,
        textTransform: "none",
        background: "#fff",
    },
});

const DownloadHeader=({parent,files,T,downloadFolder,id})=>{
    const styles = useStyles();


    return <div className="download-container-header">
        <div className="text">
            <p>{parent}&nbsp;&nbsp;</p>
            <p className="download-container-header-item-count">
                ({files.length})
            </p>
        </div>
        <Button
            className={styles.button}
            onClick={(e) => downloadFolder({ linkId: id, parent })}
        >
            <p>{T.downloadAll}</p>
            <img
                src={downloadIcon}
                alt="download"
                className="icon download-icon"
            />
        </Button>
    </div>
};
export default DownloadHeader
