import React, {useState} from "react";
import Visited from "../components/Visited";
import Header from "../components/Header";
import UploadFiles from "../components/UploadFiles";
import Input from "@material-ui/core/Input/Input";
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles} from "@material-ui/core";



const useStyles = makeStyles({
    nameInputContainer: {
        marginBottom:"2%",
        width:"60%",
        padding:"5px 20px 5px 10px",
        display:"flex",
        "&>img":{
            position:"relative",
            right: "5%",
            top: "25%",
            cursor:"pointer"
        }
    },
    nameInput: {
        width: "100%",
        fontSize: 14,
        borderBottom:"1px solid rgb(14 125 125 / 30%)",
        marginBottom:"2%",
    },
    paginationContainer: {
        display:'flex',
        justifyContent:'center'
    },

});


const AdminPage = ({
  links,
  getLinks,
  uploadFiles,
  isLoading,
  resetLastCreateLink,
  lastCreated,
  lastUpdated,
  deleteLink,
  setAdminEdit,
  isAuth,
  setIsAuth,
  adminEdit,
  limit,
  page,
  search,
  setPage,
  setSearch,
}) => {


    const editLink=(link)=>{
        window.scrollTo(0,0);
        setAdminEdit(link);
        resetLastCreateLink();
    };


    const classes = useStyles();

    const processSearch=(query)=>{
        setSearch(query);
        getLinks({search:query});
    };

    const handlePageChange=(event, newPage)=>{
        setPage(newPage);
        getLinks({search:search,page:newPage});

    };


    const pagesCount = (links.count/limit).toFixed();


    return (
    <>
      <Header isAuth={isAuth} setIsAuth={setIsAuth} />
      <div className="admin-page">
        <div className="admin-upload-files">
          <UploadFiles
              getLinks={getLinks}
              uploadFiles={uploadFiles}
              isLoading={isLoading}
              resetLastCreateLink={resetLastCreateLink}
              lastCreated={lastCreated}
              lastUpdated={lastUpdated}
              isAuth={isAuth}
              adminEdit={adminEdit}
          />
        </div>

        <div className="admin-upload-files">
          <div className="admin-upload-files-container mw-80">
            <h1>History</h1>
              <div className={classes.nameInputContainer}>
                  <Input
                      className={classes.nameInput}
                      disableUnderline
                      placeholder="Search"
                      value={search}
                      onChange={(e) => processSearch(e.target.value)}
                  />
              </div>
            {links.rows
              ? links.rows.map((link) => (
                <Visited
                  key={link._id}
                  id={link.link}
                  link={link.address}
                  ips={link.visited}
                  linkObject={link}
                  removeLink={deleteLink}
                  editLink={editLink}
                />
              ))
              : null}
              {!!links.count &&
              <div  className={classes.paginationContainer}>
                  <Pagination count={pagesCount} page={page} onChange={handlePageChange}/>
              </div>
              }
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
